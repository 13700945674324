$(document).on('turbolinks:load', loadGlobals );

function loadGlobals() {
	console.log('Loaded globals - spurimpact/globals.js');

	$('#nav_menu_btn').click(function(){
		$('.nav_contents').slideToggle();
	});

	$('.right_dropdown_btn').click(function() {
		$('.right_dropdown').slideToggle();
	})

	if ($(".success_social_share").length > 0 && navigator.share) {
		$(".success_social_share ul").append('<li><a href="#" class="nativeShare"><i class="fas fa-share-square"></i> Share</a></li>');
		$(".success_social_share").on("click", ".nativeShare", function(e) {
			var $parent = $(this).parents(".success_social_share");
			e.preventDefault();
			navigator.share({
	      title: 'Share your impact!',
	      text: $parent.data('text'),
	      url: $parent.data('url')
	    });
			return false;
		});
	}

	if ($('#current_amt').length > 0) {

		$('#current_amt').hide();
		countupInterval(0, $('#current_amt').data('current'), $('#current_amt').get(0), 1);
	}

	$('.therm').each(function() {
		var $this = $(this);
		var $progress = $(this).find('.therm_progress');
		var total = $(this).data('total');
		var current = $progress.data('current');
		var percent = current / total * 100;

		if (percent > 100)
			percent = 100;

		$progress.animate({
			width: percent + "%",
			opacity: 1
		}, 1500, function() {
			var $current = $this.find('.therm_goal_current');
			if ($current.length > 0) {
				$current.css('width', percent + "%");
				$current.fadeIn();
			}
		});


	});

	// Validation

	$('input:required').one('blur keydown input', function(e) {
		// console.log('touched', this);
		$(this).addClass('touched');
		// console.log( this.validity );

		if (this.validity.valid) {
			$(this).closest('.form_field').removeClass('form_field_error');
		} else {
			$(this).closest('.form_field').addClass('form_field_error');
		}

	});


	$('.notice').click(function(e) {
		if ( !$(this).hasClass('clickable') ) {
			e.preventDefault();
			$(this).slideUp();
		}
	});

	$('#toggle_panes').click(function() {
		$('.dtsp-panesContainer').slideToggle();
		if ( $('#toggle_panes_status').text() == "Show" ) {
			$('#toggle_panes_status').text("Hide");
		} else {
			$('#toggle_panes_status').text("Show");
		}
	});

	addEventListener("direct-upload:initialize", function (event) {
	  var target = event.target;
	  var detail = event.detail;
	  var id = detail.id;
	  var file = detail.file;

	  target.insertAdjacentHTML("beforebegin", "\n    <div id=\"direct-upload-" + id + "\" class=\"direct-upload direct-upload--pending\">\n      <div id=\"direct-upload-progress-" + id + "\" class=\"direct-upload__progress\" style=\"width: 0%\"></div>\n      <span class=\"direct-upload__filename\">" + file.name + "</span>\n    </div>\n  ");
	});

	addEventListener("direct-upload:start", function (event) {
	  var id = event.detail.id;

	  var element = document.getElementById("direct-upload-" + id);
	  element.classList.remove("direct-upload--pending");
	});

	addEventListener("direct-upload:progress", function (event) {
	  var _event$detail = event.detail;
	  var id = _event$detail.id;
	  var progress = _event$detail.progress;

	  var progressElement = document.getElementById("direct-upload-progress-" + id);
	  progressElement.style.width = progress + "%";
	});

	addEventListener("direct-upload:error", function (event) {
	  event.preventDefault();
	  var _event$detail2 = event.detail;
	  var id = _event$detail2.id;
	  var error = _event$detail2.error;

	  var element = document.getElementById("direct-upload-" + id);
	  element.classList.add("direct-upload--error");
	  element.setAttribute("title", error);
	});

	addEventListener("direct-upload:end", function (event) {
	  var id = event.detail.id;

	  var element = document.getElementById("direct-upload-" + id);
	  element.classList.add("direct-upload--complete");
	});


	// Uploads
	function fileUpload(fileInput, restrictions) {
	  var $formField = $(fileInput).closest('.form_field');

	  var imagePreview = $formField.find('.upload_preview').get(0);
	  var dashboardID = "#dashboard_container_" + $(fileInput).attr('id');
	  var triggerID = "#uppy_trigger_" + $(fileInput).attr('id');
	  restrictions = typeof restrictions !== 'undefined' ? restrictions : {};

	  fileInput.style.display = 'none' // uppy will add its own file input

	  var uppy = Uppy.Core({
	      id: fileInput.id,
	      autoProceed: true,
	      restrictions: restrictions,
	      onBeforeFileAdded: function(currentFile, files) {
	      	uppy.reset();
	      }
	    })
	    .use(Uppy.FileInput, {
	      target: fileInput.parentNode,
	    })
	    .use(Uppy.Informer, {
	      target: fileInput.parentNode,
	    })
	    .use(Uppy.ProgressBar, {
	      target: imagePreview.parentNode,
	    })
	    .use(Uppy.ThumbnailGenerator, {
	      thumbnailWidth: 400,
	    })

		// var uppy = Uppy.Core({
		//     id: fileInput.id,
		//     autoProceed: true,
		//     restrictions: restrictions
		//   })
		// .use(Uppy.Dashboard, {
		//   trigger: triggerID,
		//   inline: false,
		//   target: dashboardID,
		//   replaceTargetContent: true,
		//   showProgressDetails: true,
		//   note: restrictions.restrictionDescription,
		//   height: 200,
		//   closeAfterFinish: true,
		//   browserBackButtonClose: true,
		//   proudlyDisplayPoweredByUppy: false
		// })



	  uppy.use(Uppy.AwsS3, {
	    companionUrl: '/', // will call Shrine's presign endpoint on `/s3/params`
	  })

	  uppy.on('upload', function(data) {
	  	$("input[type=submit]").prop("disabled",true);
	  	$('#uploading_notice').slideDown();
	  	$formField.find('span').hide();
	  	$formField.find('.upload_status_progress').show();
	  	$formField.find('.upload_status').slideDown();
	  });

	  uppy.on('complete', function(result) {
	  	$("input[type=submit]").prop("disabled",false);
	  	$('#uploading_notice').slideUp();
	  })

	  uppy.on('upload-success', function (file, response) {
	    // construct uploaded file data in the format that Shrine expects
	    var uploadedFileData = JSON.stringify({
	      id: file.meta['key'].match(/^degives_cache\/(.+)/)[1], // object key without prefix
	      storage: 'cache',
	      metadata: {
	        size:      file.size,
	        filename:  file.name,
	        mime_type: file.type,
	      }
	    })

	    // set hidden field value to the uploaded file data so that it's submitted with the form as the attachment
	    var hiddenInput = $(fileInput).closest('.form_field').find('.upload_hidden').get(0);
	    hiddenInput.value = uploadedFileData;

	    $formField.find('span').hide();
	    $formField.find('.upload_status_done').show();
	    $formField.find('.upload_name').text(file.name).show();
	  })

	  uppy.on('upload-error', function(file, error, response) {
	  	console.log(file, error, response);
	  	$formField.find('span').hide();
	  	$formField.find('.upload_status_error').show();
	  });

	  uppy.on('thumbnail:generated', function (file, preview) {
	    imagePreview.src = preview;
	  })

	  return uppy;
	}

	document.querySelectorAll('.upload_file').forEach(function (fileInput) {
		// var dashboardID = "dashboard_container_" + $(fileInput).attr('id');
		// var $dasbhoardDiv = $("<div></div>").attr("id", dashboardID);
		// $(fileInput).closest(".form_field").append($dasbhoardDiv);

		// var triggerID = "uppy_trigger_" + $(fileInput).attr('id');
		// var $triggerDiv = $("<div>Upload</div>").attr("id", triggerID).addClass("btn btn_secondary btn_inline");
		// $(fileInput).closest(".form_field").append($triggerDiv);

		var $formField = $(fileInput).closest('.form_field');

		var uploadHelpers = '<div class="upload_status" style="display:none;">';
		uploadHelpers += '<span class="upload_status_progress"><i class="fas fa-cog fa-spin"></i> Uploading...</span>';
		uploadHelpers += '<span class="upload_status_done" style="display:none;"><i class="fa fa-check"></i> Uploaded </span>';
		uploadHelpers += '<span class="upload_status_error" style="display:none;"><i class="fa fa-exclamation-triangle"></i> Error</span>';
		uploadHelpers += '<span class="upload_name"></span>';
		uploadHelpers += '</div>';
		uploadHelpers += '<img class="upload_preview">';

		$formField.append(uploadHelpers);

		if ( $(fileInput).hasClass('upload_image') ) {
			fileUpload(fileInput, {
				maxNumberOfFiles: 1,
				allowedFileTypes: ['image/*'],
				maxFileSize: 5*1024*1024,
				restrictionDescription: 'Upload 1 image, up to 5 mb'
			});
		} else if ( $(fileInput).hasClass('upload_pdf') ) {
			fileUpload(fileInput, {
				maxNumberOfFiles: 1,
				allowedFileTypes: ['.pdf'],
				maxFileSize: 5*1024*1024,
				restrictionDescription: 'Upload 1 PDF, up to 5 mb'
			});
		} else {
			fileUpload(fileInput);
		}

	});

	$('.categories_select').each(function() {
		var clientHeight = this.clientHeight;
		var scrollHeight = this.scrollHeight;
		if (clientHeight != scrollHeight) {
			$(this).after("<div class='btn show_overflow'>Show all options</div>");
		}
	});

	$('.show_overflow').click(function() {
		var $category = $(this).prev();

		var clientHeight = $category[0].clientHeight;
		var scrollHeight = $category[0].scrollHeight;
		console.log(clientHeight, scrollHeight);

		$category.css({
			maxHeight: 'none',
			height: clientHeight
		});

		$category.animate({
			height: scrollHeight
		}, 1000);

		$(this).fadeOut('fast');
	});

	// $('.search_facet_filter').find('input').keyup(function(){
	$('.search_facet_filter').on('change keyup', 'input', function() {
		var val = $(this).val().toLowerCase();

		var filter_items = $(this).parents('.search_facet').find('.checkbox');
		filter_items.each(function() {
			if ( $(this).data('name').toLowerCase().indexOf(val) >= 0 ) {
				$(this).show();
			} else {
				$(this).hide();
			}
		});
	});

	$('.input_clearable').on('click', '.input_clear', function() {
		var $input = $(this).parents('.input_clearable').find('input');
		$input.val("").trigger('change');
		$(this).hide();
	});

	$('.input_clearable').find('input').keyup(function() {
		var $clearable = $(this).parents('.input_clearable');

		if ( $clearable.find('.input_clear').length == 0 ) {
			var clearBtn = '<div class="input_clear">&times;</div>';
			$clearable.append(clearBtn);
		} else {
			$clearable.find('.input_clear').show();
		}

		if ( $(this).val() == "" ) {
			$clearable.find('.input_clear').hide();
		}


	});

	$('.category_restriction_field').change(function() {
		var val = $(this).val();
		$('.category_restrictions').slideUp();
		$(':input', '.category_restrictions').val('').prop('selected', false);
		$('.restricted_category_id_field').val('');

		if (val == 'none') {
			return;
		}

		$('#' + val).slideDown();
	});

	$('.category_restrictions').find('select').change(function() {
		$('.restricted_category_id_field').val( $(this).val() );
	});

	if (typeof jQuery.ui !== 'undefined') {
		// $('#sort_leaderboards').find('.sortable').sortable({
		$('.sortable').sortable({
			connectWith: '.sortable',
			change: function(event, ui) {
				var $item = $(ui.item);
				var $input = $item.find('input');
				var fname = $(event.target).data('fname');
				$input.attr('name', fname + '[]');
			},
			update: function(event, ui) {
				if ( $('.auto_reorder').length > 0 ) {
					$('.auto_reorder').submit();
				}
			}
		}).disableSelection();
	}

	$('#company_input').change(function() {
		if ($(this).val() == "") {
			$('.show_for_companies').slideUp();
			$(':input', '.show_for_companies').val('')
		} else {
			$('.show_for_companies').slideDown();
		}

	});

	$('#fundraiser_organization_id').change(function() {
		var $this = $(this);
		if ($this.val() != "") {
			$('#fundraiser_parent_child_help').slideUp('fast');

			var $parentSelect = $('#fundraiser_parent_fundraiser_id');
			var $loading = $('#parent_fundraiser_loading');
			var $error = $('#parent_fundraiser_error');

			$parentSelect.empty();
			$parentSelect.append('<option value></option>');
			$loading.slideDown('fast');
			$error.slideUp('fast');

	 		$.getJSON('/list_possible_parents', {
				event_id: $this.data('event-id'),
				organization_id: $this.val(),
				fundraiser_id: $this.data('fundraiser-id')
			}).done(function(data) {
				console.log(data);
				$loading.slideUp('fast');
				if (data.error_text) {
					$error.text(data.error_text);
					$error.slideDown();
				} else {
					$.each(data.possible_parents, function(index, value) {
						var html = '<option value="' + value.id + '">' + value.title + '</option>';
						$parentSelect.append(html);
					});
				}
			});
		}
	});

	$('#show_embed').click(function(e) {
		e.preventDefault();
		$("#embed_modal_outer").fadeIn();
		return false;
	});

	$("#embed_code, #embed_copy").click(function() {
		$("#embed_was_copied").slideUp();
		var code = document.getElementById("embed_code");
		code.select();
		code.setSelectionRange(0, 99999);
		document.execCommand("copy");
		code.blur();
		if (window.getSelection) { // All browsers, except IE <=8
		  window.getSelection().removeAllRanges();
		} else if (document.selection) { // IE <=8
		  document.selection.empty();
		}
		$("#embed_was_copied").slideDown();
	});

	$("#embed_close, #embed_modal_outer").click(function(e) {
		if(e.target !== e.currentTarget) return;
		$("#embed_modal_outer").fadeOut();
	})

	$("#embed_preview").click(function(e) {
		e.preventDefault();
		return false;
	});

	// if (  $('.form') ) {
	// 	// Initial check
	// 	$('.form :input').each(function() {
	// 		var $target = $(this);
	// 		getGroupDependencies($target);
	// 	});
	// }

	$('.form').change(function(e){
		var $target = $(e.target);
		getGroupDependencies($target);
	});

	$('.admin_guide_btn').click(function() {
		$(this).next('.admin_guide').slideToggle('fast');
	});

	function clearFields(target) {
		$(':input',target)
		  .not(':button, :submit, :reset, :hidden')
		  .val('')
		  .prop('checked', false)
		  .prop('selected', false);
	}

	function getGroupDependencies(target) {
		var $target = $(target);
		if ( $target.data('groupdependency') != undefined ) {
			var group = $target.data('groupdependency');
			var $group = $('.' + group);
			clearFields($group);
			$group.slideUp('fast');
			var id = $target.attr('id');
			$('#group_' + id).slideDown('fast');
		}
	}

};


$(window).on('load', function(){
  // Please run it with window.onload, not with document.ready
  initSmoothScrolling('.home_carousel','smoothscroll');
});

function initSmoothScrolling(container,animation){
	// https://codepen.io/wschern/pen/grXwra
 /*
	* @param {String} container Class or ID of the animation container
	* @param {String} animation Name of the animation, e.g. smoothscroll
	*/


	var sliderWidth = 0;
	var animationWidth = 0;
	var sliderHeight = $('>div>div:first-of-type',container).outerHeight(false);

	$('>div>div', container).each(function(){
		animationWidth += $(this).outerWidth(false);
	});

	// detect number of visible slides
	var slidesVisible = $(container).width() / $('>div>div:first-of-type',container).outerWidth(false);
	slidesVisible = Math.ceil(slidesVisible);


  // count slides to determine animation speed
	var slidesNumber = $('>div>div', container).length;
	var speed = slidesNumber*10;

// append the tail
	$('>div>div',container).slice(0,slidesVisible).clone().appendTo($('>div',container));

	// Detect the slider width with appended tail
	$('>div>div', container).each(function(){
		sliderWidth += $(this).outerWidth(false);
	});

	// set slider dimensions
	$('>div',container).css({'width':sliderWidth,'height':sliderHeight});


// Insert styles to html
	$("<style type='text/css'>@keyframes "+animation+" { 0% { margin-left: 0px; } 100% { margin-left: -"+animationWidth+"px; } } "+container+" >div>div:first-of-type { -webkit-animation: "+animation+" "+speed+"s linear infinite; -moz-animation: "+animation+" "+speed+"s linear infinite; -ms-animation: "+animation+" "+speed+"s linear infinite; -o-animation: "+animation+" "+speed+"s linear infinite; animation: "+animation+" "+speed+"s linear infinite; }</style>").appendTo("head");

	// restart the animation (e.g. for safari & ie)
	var cl = $(container).attr("class");
	$(container).removeClass(cl).animate({'nothing':null}, 1, function () {
		$(this).addClass(cl);
	});
}

function countupInterval(startVal, endVal, el, duration_secs) {
	console.log(el);
	el.innerHTML = startVal;
	el.style.visibility = "visible";
	el.style.display = "inline";
	var intervalMS = Math.round((duration_secs * 1000) / Math.abs((endVal - startVal)));

	var counter = startVal;
	var interval = setInterval(function(){
		if (counter <= endVal) {
			el.innerHTML = counter.toLocaleString();
			counter += 512;
		} else {
			clearInterval(interval);
		}
	}, intervalMS);
}

window.addEventListener("trix-file-accept", function(event) {
  const acceptedTypes = ['image/jpeg', 'image/png', 'image/gif'];
  if (!acceptedTypes.includes(event.file.type)) {
    event.preventDefault();
    alert("Only image uploads are supported (jpg, png, gif). Please choose another file.");
  }
});