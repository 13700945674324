$(document).on('turbolinks:load', function() {
	$('form').submit(function(e) {
		var $this = $(this);

		var $token = $('#recaptcha_token');
		if ($token.length > 0) {
			e.preventDefault();
			// Create or find spinner
			var $spinner = $('#recaptcha_spinner');
			if ($spinner.length == 0) {
				$spinner = $('<div class="notice" id="recaptcha_spinner"><i class="fas fa-cog fa-spin"></i> Submitting...</div>');
				$spinner.hide()
				$('input[type=submit]').before($spinner);	
			}
			$spinner.slideDown();
			
			// Generate or re-generate token
			var action = $('#recaptcha_action').val() || "form_submit";
			console.log(action);
			grecaptcha.execute('6Ld2h8UUAAAAAF_23uzFZhxWHRZvSGczxCBJz3sA', {action: action}).then(function(token) {
				$token.val(token);
				$spinner.slideUp();
				$this.unbind().submit();
			});
			
		}

	});
} );
